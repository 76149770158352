export const peformance = [
  {
    type: 'kpi',
    title: 'WTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([WTD]/[WTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'WTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'MTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([MTD]/[MTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'MTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'QTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([QTD]/[QTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'QTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'YTD Sales',
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([YTD]/[YTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'YTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(variance='Last Year','LY','LLY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'line',
    title: 'Sales evolution',
    md: 12,
    xs: 12,
    chartConfig: { categoryLabel: 'monthofyear', metrics: ['YTD', 'Comparison'], isDate: false },
    cols: [
      {
        qField: 'monthofyear',
        dataKey: 'monthofyear',
        qLabel: 'monthofyear',
      },
      {
        qField: '=Round(YTD)',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=Round(YTDComparable)',
        dataKey: 'Comparison',
        qLabel: 'Comparison',
      },
      {
        qField: "=if(YTD<>0,if(metric='Volume',0,1),3)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: '=only({<year = {$(asOfYear)}> } monthid)',
        dataKey: 'maxmonthid',
        qLabel: 'maxmonthid',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Sales Type',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYMTDUPT',
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDUPT',
      'vsLYWTDUPT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'WTDAVT',
      'vsLYWTDAVT',
    ],
    options: {
      coreColumns: ['Sales Type'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'vsLYMTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: '',
        qLibraryId: 'tdBtwtv',
        qType: 'dimension',
        dataKey: 'Sales Type',
        qLabel: 'Sales Type',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Channel',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['Channel'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['Source', 'Location Type'],
        dataKey: 'Channel',
        qLabel: 'Channel',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Brand',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['Brand'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['BrandName'],
        dataKey: 'Brand',
        qLabel: 'Brand',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },

  {
    type: 'table',
    title: 'Sales by Category',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['Sales Type'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[Function]', '[line]'],
        dataKey: 'Function',
        qLabel: 'Function',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Product',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
    ],
    options: {
      coreColumns: ['Product'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        { name: 'Actual', hideColumns: ['WTD Mix', 'vsWTDPP', 'MTD Mix', 'vsMTDPP', 'YTD Mix', 'vsYTDPP'] },
        {
          name: 'Mix',
          hideColumns: ['WTD', 'vs LYWTD', 'vs LYWTD%', 'MTD', 'vs LYMTD', 'vs LYMTD%', 'YTD', 'vs LYYTD', 'vs LYYTD%'],
        },
      ],
    },
    cols: [
      {
        qField: [
          "=[Prod Colour Code] & 'SPLIT' & Capitalize([Style Description])  & 'SPLIT' & Capitalize([Colour Description]) & 'SPLIT' & [URL]",
        ],
        dataKey: 'Product',
        qLabel: 'Product',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Geography',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
      'vsLYMTDUPT',
    ],
    options: {
      coreColumns: ['Sales Type'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'vsLYMTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['region', 'country_name'],
        dataKey: 'Region',
        qLabel: 'Region',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Location',
    md: 12,
    xs: 12,
    qPage: { qWidth: 30, qHeight: 333 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'vsLYMTDUPT',
      'WTDAVT',
      'vsLYWTDAVT',
      'MTDAVT',
      'vsLYMTDAVT',
      'YTDAVT',
      'vsLYYTDAVT',
      'YTDUPT',
      'vsLYYTDUPT',
      'MTDUPT',
      'vsLYWTDUPT',
      'WTDUPT',
    ],
    options: {
      coreColumns: ['Sales Type'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
        {
          name: 'Mix',
          hideColumns: [
            'vsLYMTDUPT',
            'WTDAVT',
            'vsLYWTDAVT',
            'MTDAVT',
            'vsLYMTDAVT',
            'YTDAVT',
            'vsLYYTDAVT',
            'YTDUPT',
            'vsLYYTDUPT',
            'MTDUPT',
            'vsLYWTDUPT',
            'WTDUPT',
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
          ],
        },
        {
          name: 'Tickets',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'YTD',
            'vs LYYTD',
            'vs LYYTD%',
            'WTD Mix',
            'vsWTDPP',
            'MTD Mix',
            'vsMTDPP',
            'YTD Mix',
            'vsYTDPP',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['Shipping Location Name'],
        dataKey: 'Location',
        qLabel: 'Location',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },
      {
        qField: '=WTDMix - WTDComparableMix',
        dataKey: 'vsWTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },
      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTDMix]',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },
      {
        qField: '=MTDMix - MTDComparableMix',
        dataKey: 'vsMTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD]',
        dataKey: 'YTD',
        qLabel: 'YTD',
      },
      {
        qField: '=[YTD]-[YTDComparable]',
        dataKey: 'vs LYYTD',
        qLabel: 'vs LY',
      },
      {
        qField: '=([YTD]/[YTDComparable]) - 1',
        dataKey: 'vs LYYTD%',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTDMix]',
        dataKey: 'YTD Mix',
        qLabel: 'YTD Mix',
      },
      {
        qField: '=YTDMix - YTDComparableMix',
        dataKey: 'vsYTDPP',
        qLabel: 'vs LY',
      },
      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='Last Year','vs LY','vs LLY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },
      {
        qField: '=[WTD UPT]',
        dataKey: 'WTDUPT',
        qLabel: 'WTD UPT',
      },
      {
        qField: '=[WTD UPT] - [WTD UPT Comparable]',
        dataKey: 'vsLYWTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[WTD AVT]',
        dataKey: 'WTDAVT',
        qLabel: 'WTD AVT',
      },
      {
        qField: '=[WTD AVT]-[WTD AVT Comparable]',
        dataKey: 'vsLYWTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD UPT]',
        dataKey: 'MTDUPT',
        qLabel: 'MTD UPT',
      },
      {
        qField: '=[MTD UPT] - [MTD UPT Comparable]',
        dataKey: 'vsLYMTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[MTD AVT]',
        dataKey: 'MTDAVT',
        qLabel: 'MTD AVT',
      },
      {
        qField: '=[MTD AVT]-[MTD AVT Comparable]',
        dataKey: 'vsLYMTDAVT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD UPT]',
        dataKey: 'YTDUPT',
        qLabel: 'YTD UPT',
      },
      {
        qField: '=[YTD UPT] - [YTD UPT Comparable]',
        dataKey: 'vsLYYTDUPT',
        qLabel: 'vs LY',
      },
      {
        qField: '=[YTD AVT]',
        dataKey: 'YTDAVT',
        qLabel: 'YTD AVT',
      },
      {
        qField: '=[YTD AVT]-[YTD AVT Comparable]',
        dataKey: 'vsLYYTDAVT',
        qLabel: 'vs LY',
      },
    ],
  },
];

export const filters = [
  {
    title: 'metric',
    dimension: 'metric',
    icon: 'eva:pricetags-fill',
    lock: true,
  },
  {
    title: 'Comparison',
    dimension: 'variance',
    icon: 'eva:calendar-fill',
    lock: true,
  },
  {
    title: 'As Of',
    dimension: '[as of]',
    icon: 'bx:bxs-calendar-edit',
    singleSelect: true,
  },
  {
    title: 'Brand',
    dimension: '[BrandName]',
    icon: 'whh:design',
  },
  {
    title: 'Channel',
    dimension: 'Source',
    icon: 'eva:pin-fill',
  },
  {
    title: 'Sales Type',
    dimension: 'tdBtwtv',
    icon: 'eva:pin-fill',
    masterDimension: true,
  },
  {
    title: 'Region',
    dimension: 'region',
    icon: 'eva:globe-2-fill',
  },
  {
    title: 'Location',
    dimension: 'Shipping Location Name',
    icon: 'eva:home-fill',
  },
  {
    title: 'Gender',
    dimension: '[gender]',
    icon: 'eva:person-fill',
  },
  {
    title: 'Season',
    dimension: '[Born Collection]',
    icon: 'eva:sun-fill',
  },
  {
    title: 'Function',
    dimension: '[Function]',
    icon: 'mdi:tshirt-crew',
  },

  {
    title: 'Macro Cat.',
    dimension: 'Descr. Macrocategoria',
    icon: 'mdi:tshirt-crew',
  },
  {
    title: 'Micro Cat.',
    dimension: 'Descr. Microcategoria',
    icon: 'mdi:tshirt-crew',
  },
];

export const bestsellers = [
  {
    type: 'bestseller',
    title: 'bestseller',
    qPage: { qWidth: 15, qHeight: 100 },
    sortColumn: 6,
    cols: [
      {
        qField: 'Master Prod Colour Code',
        dataKey: 'ProdCode',
        qLabel: 'ProdCode',
      },
      {
        qField: "=only({<is_master={'Y'},[Data]=>} [Style Description])",
        dataKey: 'ProdDsc',
        qLabel: 'ProdDsc',
      },
      {
        qField: "=only({<is_master={'Y'},[Data]=>} [Colour Description])",
        dataKey: 'ProdColor',
        qLabel: 'ProdColor',
      },
      {
        qField: "=only({<is_master={'Y'},[Data]=>} [URL])",
        dataKey: 'URL',
        qLabel: 'URL',
      },
      {
        qField: "=only({<is_master={'Y'},[Data]=>} [BrandName])",
        dataKey: 'ProdBrand',
        qLabel: 'ProdBrand',
      },
      {
        qField:
          "='Rtl ' & Num(only({<is_master={'Y'},[Data]=>} [rrp_euro]),'€###0') & ' WS ' & Num(only({<is_master={'Y'},[Data]=>} [ws_euro]),'€###0')",
        dataKey: 'RRPEuro',
        qLabel: 'RRPEuro',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(${ValueVolumeMetric})',
        dataKey: 'Sales',
        qLabel: 'Sales',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(${ValueMetric})',
        dataKey: 'SalesValue',
        qLabel: 'SalesValue',
      },
      {
        // eslint-disable-next-line
        qField: '=sum(${VolumeMetric})',
        dataKey: 'SalesVolume',
        qLabel: 'SalesVolume',
      },
      {
        // eslint-disable-next-line
        qField: '=currentOnHand',
        dataKey: 'currentOnHand',
        qLabel: 'currentOnHand',
      },
    ],
  },
];

// {
//   type: 'stackedbar',
//   title: 'Sales Mix',
//   md: 4,
//   xs: 12,
//   chartConfig: { dimension: 'dummy', metrics: { one: 'YTD', two: 'Comparison' } },
//   cols: [
//     {
//       qField: 'Function',
//       dataKey: 'dummy',
//       qLabel: 'dummy',
//     },
//     {
//       qField: '=Round(YTDMix * 100)',
//       dataKey: 'YTD',
//       qLabel: 'YTD',
//     },
//     {
//       qField: '=Round(YTDComparableMix * 100)',
//       dataKey: 'Comparison',
//       qLabel: 'Comparison',
//     },
//     {
//       qField: "=if(YTDMix>0.05,Function,'Other')",
//       dataKey: 'dimension',
//       qLabel: 'dimension',
//     },
//   ],
// },
