import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
// config
import { filters } from '../../../config/app';
import { Filter } from '../../qlik';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.object,
};

// generate the paging buttons & filters

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  // get the paging options from the navConfig file and generate under apps section
  const { subheader, items } = navConfig;

  return (
    <Box {...other}>
      <List key={subheader} disablePadding sx={{ px: 2 }}>
        <ListSubheaderStyle
          sx={{
            ...(isCollapse && {
              opacity: 0,
            }),
          }}
        >
          {subheader}
        </ListSubheaderStyle>

        {items.map((list) => (
          // loop through all the pages and generate button

          <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
        ))}
      </List>

      {/* add filters
       */}
      <List key="filters" disablePadding sx={{ px: 2, mb: 2 }}>
        <ListSubheaderStyle
          sx={{
            ...(isCollapse && {
              opacity: 0,
            }),
          }}
        >
          Filters
        </ListSubheaderStyle>

        {filters.map((filter, i) => (
          <Filter key={i} isCollapse={isCollapse} filter={filter} />
        ))}
      </List>
    </Box>
  );
}
