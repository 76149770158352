import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// utils
import { fCurrency, fNumber } from '../../utils/formatNumber';
import { fTitleCase } from '../../utils/formatText';

// components
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';
import Label from '../Label';

// components
import Image from '../Image';

// ----------------------------------------------------------------------

ProductCard.propTypes = {
  product: PropTypes.object,
  handleModalOpen: PropTypes.func,
};

const PalmAngels = `${process.env.PUBLIC_URL}/brands/PAFav.png`;
const OW = `${process.env.PUBLIC_URL}/brands/OWFav.png`;

const brandImageMapping = {
  'OFF-WHITE': OW,
  'PALM-ANGELS': PalmAngels,
};

export default function ProductCard({ product, handleModalOpen }) {
  const { ProdCode, ProdDsc, ProdBrand, ProdColor, SalesValue, SalesVolume, URL, RRPEuro } = product;

  const cover = 'https://d1j9x9sbg3f3rb.cloudfront.net/standard_no_image.jpg';
  const theme = useTheme();

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 9999, filter: 'opacity(0.25)' }}>
          {brandImageMapping[ProdBrand.text] && <img src={brandImageMapping[ProdBrand.text]} alt="" height={25} />}
        </Box>

        <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 9999 }}>
          <IconButtonAnimate onClick={() => handleModalOpen(ProdCode)}>
            <Iconify icon="eva:more-vertical-outline" width={20} height={20} />
          </IconButtonAnimate>
        </Box>

        <Image alt={ProdCode.text} src={URL.text === '-' ? cover : URL.text} ratio="1/1" />
      </Box>
      <Stack spacing={0} sx={{ p: 3 }}>
        <Typography variant="body3" noWrap>
          {fTitleCase(ProdDsc.text)}

          <Typography variant="body3" noWrap sx={{ display: 'block' }}>
            {fTitleCase(ProdColor.text)}
          </Typography>

          <Box sx={{ color: theme.palette.grey[500] }}>
            <Typography variant="body3" noWrap sx={{ display: 'block' }}>
              {ProdCode.text}
            </Typography>

            <Typography variant="body3" noWrap sx={{ display: 'block' }}>
              {RRPEuro.text}
            </Typography>
          </Box>
        </Typography>

        <Box sx={{ mt: 1, mb: 1 }} />

        <Typography variant="body3">
          Sales
          <Label variant="ghost" color="default" sx={{ ml: 1 }}>
            {fCurrency(SalesValue.number)}
          </Label>
          <Label variant="ghost" color="default" sx={{ ml: 1 }}>
            {`${fNumber(SalesVolume.number)} units`}
          </Label>
        </Typography>

        {/* <Typography variant="body3" sx={{ mt: 0.5 }}>
          Inventory
          <Label variant="ghost" color="default" sx={{ ml: 1 }}>
            {`${fNumber(currentOnHand.number)} units`}
          </Label>
        </Typography> */}
      </Stack>
    </Card>
  );
}
