// @mui
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import {
  Box,
  List,
  Divider,
  Typography,
  ListItemText,
  ListItemButton,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import { useBookmark } from '@motor-js/engine';
// utils
// components
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';
import MenuPopover from '../MenuPopover';
import { Mixpanel } from '../../utils/mixPanel';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

Bookmark.propTypes = {};

export default function Bookmark() {
  const theme = useTheme();

  const [open, setOpen] = useState(null);
  const [name, setName] = useState('');

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { bookmarkList, appliedBookmark, createBookmark, destroyBookmark, applyBookmark } = useBookmark();

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Iconify icon="eva:book-fill" width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Bookmarks</Typography>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 2, mr: 2 }}>
          <TextField
            inputProps={{
              style: {
                padding: 5,
                backgroundColor: '#f4f6f8',
                borderRadius: '5px',
                textIndent: '5px',
              },
            }}
            margin="dense"
            placeholder="enter a name"
            value={name}
            onChange={handleChange}
            sx={{ mt: 2, mb: 2, width: '70%' }}
          />

          <Tooltip title="create a bookmark" sx={{ ml: 2 }}>
            <>
              <IconButton
                aria-label="delete"
                disabled={!name}
                onClick={() => {
                  createBookmark(name);
                  setName('');
                  Mixpanel.track('create bookmark', { name });
                }}
              >
                <AddIcon sx={{ color: name ? theme.palette.primary.main : theme.palette[200] }} />
              </IconButton>
            </>
          </Tooltip>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box
          sx={{
            height: { xs: 340, sm: 'auto' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
            mt: 2,
            width: '100%',
          }}
        >
          {bookmarkList ? (
            bookmarkList.length > 0 ? (
              <List sx={{ width: '100%', pl: 0, pr: 2 }}>
                {bookmarkList.map(({ id, title }) => (
                  <Box key={id} sx={{ display: 'flex', alignItems: 'space-between' }}>
                    <ListItemButton
                      component="a"
                      onClick={() => applyBookmark(id)}
                      sx={{ pl: 2, borderTopRightRadius: '15px', borderBottomRightRadius: '15px' }}
                    >
                      <ListItemText
                        primary={title}
                        sx={{ color: id === appliedBookmark?.id && theme.palette.primary.main }}
                      />
                    </ListItemButton>
                    <Tooltip title="delete bookmark">
                      <IconButton aria-label="delete" onClick={() => destroyBookmark(id)}>
                        <DeleteIcon sx={{ color: name ? theme.palette.primary.main : theme.palette[200] }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              </List>
            ) : (
              <Box sx={{ fontSize: '14px' }}>You have no bookmarks</Box>
            )
          ) : (
            <Skeleton />
          )}
        </Box>
      </MenuPopover>
    </>
  );
}
