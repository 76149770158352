// deployed

const appIDs = {
  'new data model': '9899044e-2aa8-4dc5-8e7d-618e8968546b',
  // 'test app': '415b4e05-3756-4619-82df-c57e768a6c41',
};

export default {
  host: '192.168.101.13',
  secure: true,
  port: 19077,
  prefix: 'autone',
  // appId: 'b159d86c-34c5-4d1e-bce1-bd27718d9d3e',
  appId: appIDs['new data model'],
  // redirectFileName: 'autone_local_direct.html',
  redirectFileName: 'autone_performance_redirect.html',
  qsServerType: 'onPrem',
};
