import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useData } from '@motor-js/engine';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { orderBy } from 'lodash';

// @mui
import { Card, CardHeader, Box, Skeleton } from '@mui/material';
// components
import { BaseOptionChart } from '../chart';
import useResponsive from '../../hooks/useResponsive';
import { fValueVolume } from '../../utils/formatNumber';
import { IconButtonAnimate } from '../animate';
import { exportToPDF } from '../../utils/exportToPDF';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  chartConfig: PropTypes.object.isRequired,
};

export default function LineChart({ title, cols, chartConfig }) {
  const theme = useTheme();

  const { categoryLabel, metrics, isDate } = chartConfig;

  const { dataSet } = useData({
    cols,
    qPage: { qWidth: 15, qHeight: 52 },
  });

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: dataSet.data
        ? orderBy(dataSet.data, 'maxmonthid')
            .filter((item) => item.metric !== 3)
            .map((item) => (isDate ? moment(item[categoryLabel], 'DD/MM/YYYY').toDate() : item[categoryLabel]))
        : [],
      labels: {
        formatter: (value) => `${value}`,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fValueVolume(value, dataSet.data && dataSet.data[0].metric === 1)}`,
      },
    },
    tooltip: {
      enabled: true,
      offsetY: 0,
      y: {
        formatter: (value) => `${fValueVolume(value, dataSet.data && dataSet.data[0].metric === 1)}`,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
  });

  const chartData = dataSet.data
    ? metrics.map((metric) => ({
        name: metric,
        data: dataSet.data.filter((item) => item.metric !== 3).map((item) => item[metric]),
      }))
    : [];

  const height = useResponsive('up', 'lg') ? 300 : 250;

  return (
    <Card>
      {dataSet.data ? (
        <>
          <CardHeader
            title={title}
            action={
              dataSet === undefined ? (
                <Skeleton width={200} height={35} />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButtonAnimate sx={{ mr: 1, color: theme.palette.grey[500] }} onClick={() => exportToPDF(title)}>
                    <Iconify icon="el:picture" sx={{ height: 20 }} />
                  </IconButtonAnimate>
                </Box>
              )
            }
          />

          <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <ReactApexChart id={title} type="area" series={chartData} options={chartOptions} height={height} />
          </Box>
        </>
      ) : (
        <Box sx={{ m: 2 }}>
          <Skeleton height={60} />
          <Skeleton height={200} />
        </Box>
      )}
    </Card>
  );
}
