import { ListItem, Typography, Box, Chip, List, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useButton } from '@motor-js/engine';
import SelectIcon from '@mui/icons-material/CheckCircle';

export default function SearchResult({ result, groupSelect }) {
  const { dimension, value, id } = result;

  const ChipListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const { selectValues } = useButton();

  return (
    <ListItem>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          width: '100%',
          pl: 6,
        }}
      >
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {dimension}
        </Typography>

        <List
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
        >
          <IconButton color="primary" aria-label="selectall" component="span" onClick={() => groupSelect(id)}>
            <SelectIcon />
          </IconButton>

          {value.map((data) => (
            <ChipListItem key={data.qText}>
              <Chip label={data.qText} onClick={() => selectValues([data.qText], dimension,true)} />
            </ChipListItem>
          ))}
        </List>
      </Box>
    </ListItem>
  );
}

SearchResult.propTypes = {
  result: PropTypes.object,
  groupSelect: PropTypes.func,
};
