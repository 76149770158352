import { Chip } from '@mui/material';
import { useButton, useSelections } from '@motor-js/engine';

export default function Selection() {
  const { selections } = useSelections();
  const { select } = useButton();

  return (
    <>
      {selections &&
        selections.map(
          (item, i) =>
            !['options'].includes(item.qField) && (
              <Chip
                onDelete={
                  ['metric', 'variance'].includes(item.qField) ? null : () => select(`${item.qSelected},`, item.qField)
                }
                key={i}
                label={item.qSelected}
              />
            )
        )}
    </>
  );
}
